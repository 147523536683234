import { GetStaticPaths, GetStaticProps } from 'next'

import { serverSideTranslations } from '@dao-dao/i18n/serverSideTranslations'
import {
  dehydrateSerializable,
  globalQueryClient,
  wasmaticQueries,
} from '@dao-dao/state'
import { Home, StatefulHomeProps } from '@dao-dao/stateful'
import { ACTIVE_LAYER_CHAIN_ID } from '@dao-dao/utils'

export default Home

export const getStaticProps: GetStaticProps<StatefulHomeProps> = async ({
  locale,
}) => {
  const [i18nProps, operators, apps] = await Promise.all([
    serverSideTranslations(locale, ['translation']),
    globalQueryClient
      .fetchQuery(
        wasmaticQueries.listOperators({
          chainId: ACTIVE_LAYER_CHAIN_ID,
        })
      )
      .catch(() => []),
    globalQueryClient
      .fetchQuery(
        wasmaticQueries.listApps(globalQueryClient, {
          chainId: ACTIVE_LAYER_CHAIN_ID,
        })
      )
      .catch(() => []),
  ])

  return {
    props: {
      ...i18nProps,
      stats: {
        commitments: 0,
        avs: apps.length,
        operators: operators.length,
      },
      // Dehydrate react-query state with featured DAOs preloaded.
      reactQueryDehydratedState: dehydrateSerializable(globalQueryClient),
    },
    // Revalidate every 5 minutes.
    revalidate: 60 * 5,
  }
}

export const getStaticPaths: GetStaticPaths = () => ({
  paths: [
    // Index page with no tab specified.
    {
      params: {
        tab: [],
      },
    },
  ],
  fallback: false,
})
